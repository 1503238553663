<template>
  <div class="stake-container">
    <div class="nav-box">
      <!--      <img class="logo" src="/static/image/logo.jpg" />-->
      <div>&nbsp;</div>
      <div style="display: flex; align-items: center">
        <div class="invite-code" v-if="userDetail && userDetail.nickname">
          nickname:
          <a @click="showEditNickname" style="margin-left: 0px">
            {{ userDetail.nickname }}
            <svg
              t="1709277466880"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1468"
              width="14"
              height="14"
            >
              <path
                d="M684.202667 117.248c15.893333-15.872 42.154667-15.36 58.922666 1.408l90.517334 90.517333c16.661333 16.661333 17.344 42.986667 1.429333 58.922667l-445.653333 445.653333c-7.936 7.914667-23.104 16.746667-34.218667 19.776l-143.701333 39.253334c-21.909333 5.994667-35.114667-7.104-29.568-28.949334l37.248-146.773333c2.773333-10.944 11.562667-26.346667 19.392-34.176l445.653333-445.653333zM268.736 593.066667c-2.901333 2.901333-8.106667 12.074667-9.130667 16.021333l-29.12 114.773333 111.957334-30.570666c4.437333-1.216 13.632-6.549333 16.810666-9.728l445.653334-445.653334-90.517334-90.496-445.653333 445.653334zM682.794667 178.986667l90.517333 90.517333-30.186667 30.186667-90.496-90.517334 30.165334-30.165333z m-362.026667 362.048l90.496 90.517333-30.165333 30.165333-90.517334-90.496 30.165334-30.186666zM170.666667 874.666667c0-11.776 9.429333-21.333333 21.461333-21.333334h661.077333a21.333333 21.333333 0 1 1 0 42.666667H192.128A21.333333 21.333333 0 0 1 170.666667 874.666667z"
                fill="#3D3D3D"
                p-id="1469"
              ></path>
            </svg>
          </a>
        </div>
        <div class="invite-code" v-if="userDetail && userDetail.invite_code">
          code: {{ userDetail.invite_code }}
        </div>
        <div
          class="address text-unselect cursor"
          v-if="walletAddress && walletAddress.length > 1"
        >
          {{ getAddress(walletAddress) }}
        </div>
        <div v-else class="btn-connect cursor" @click="connectMetamaskBtnFun">
          Connect Wallet
        </div>
      </div>
    </div>
    <!--    <div class="date-info-box list-container">-->
    <!--      <div>-->
    <!--        Days:<span class="date-value">{{ parseInt(days) }}</span>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        Recruitment start:<span class="date-value">{{ startingTime }}</span>-->
    <!--      </div>-->
    <!--      <div class="bright-2"></div>-->
    <!--    </div>-->
    <ton-profile-list
      class="ton-profile-list-container list-container"
      :tonPrice="tonPrice"
      :aprValue="aprValueToShow"
    ></ton-profile-list>
    <progress-info
      class="progress-info-container list-container"
      :stakeMilestone="stakeMilestone"
    ></progress-info>
    <data-list
      class="data-list-container list-container"
      :stakingTon="stakingTon"
      :userDetail="userDetail"
      @btnStake="btnStake"
      @connectWallet="connectMetamaskBtnFun"
      @btnWithdraw="btnWithdraw"
    >
    </data-list>
    <!--    <user-profile-list-->
    <!--      class="user-profile-list-container list-container"-->
    <!--      :stakingTon="stakingTon"-->
    <!--      :userDetail="userDetail"-->
    <!--      @btnStake="btnStake"-->
    <!--      @connectWallet="connectMetamaskBtnFun"-->
    <!--      @btnWithdraw="btnWithdraw"-->
    <!--    ></user-profile-list>-->
    <edit-nickname-dialog
      ref="editNicknameDialogRef"
      @save="getUserDetail"
    ></edit-nickname-dialog>
    <bind-code-dialog
      ref="bindCodeDialogRef"
      @save="getUserDetail"
    ></bind-code-dialog>
    <input-dialog ref="inputDialogRef" @save="inputDialogSave"></input-dialog>
    <withdraw-input-dialog
      ref="withdrawInputDialogRef"
      @save="withdrawInputDialogSave"
    ></withdraw-input-dialog>
    <loading-dialog ref="loadingDialogRef">
      <template>
        <div class="loading-box">
          <img class="icon-upload" src="/static/image/icon-upload.png" alt="" />
          <div class="loading-text">{{ loadingMessage }}</div>
          <div
            class="loading-view-text cursor"
            v-show="txHash && txHash.length > 2"
            @click="goToViewEtherscan"
          >
            View on Etherscan
          </div>
          <div class="btn-close cursor" @click="closeLoadingDialogRef">
            Close
          </div>
        </div>
      </template>
    </loading-dialog>
    <v-dialog
      v-model="showTipDialog"
      max-width="240px"
      class="show-tip-content"
    >
      <v-card class="show-tip-content">
        <div class="show-tip-text">You need to get nft first.</div>
        <v-card-actions class="justify-end">
          <div class="btn-confirm cursor" @click="closeShowTipDialog">
            close
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    <RewardHistoryDataTable-->
    <!--      v-if="walletAddress && walletAddress.length > 1"-->
    <!--      class="list-container"-->
    <!--      style="margin-top: 50px; background-color: #140f24"-->
    <!--    ></RewardHistoryDataTable>-->
    <subuser v-if="this.subUsers" :subUserData="this.subUsers"> </subuser>
  </div>
</template>

<script>
import {
  // MaxUint256,
  // check_transaction_receipt_single,
  recharge_platform_account,
} from "@/utils/connet";
import { getContract, getProvider, isMetaMaskInstalled } from "@/utils/index";
import { mapState } from "vuex";
// import UserProfileList from "@/components/StakeTemp/UserProfileList.vue";
import dataList from "@/components/StakeTemp/dataList.vue";
import TonProfileList from "@/components/StakeTemp/TonProfileList.vue";
import ProgressInfo from "@/components/StakeTemp/ProgressInfo.vue";
import ContractAbi from "@/common/abi/ContractAbi.json";
import Erc721ContractAbi from "@/common/abi/erc721Abi.json";
// import RewardHistoryDataTable from "@/components/StakeTemp/RewardHistoryDataTable.vue";
// import TonStakeAbi from "@/common/abi/TonStake.json";
import { BigNumber } from "ethers";
import Login from "@/views/script/login.js";
import InputDialog from "@/components/StakeTemp/inputDialog";
import BindCodeDialog from "@/components/StakeTemp/bindCodeDialog";
import LoadingDialog from "@/components/StakeTemp/loadingDialog";
import config from "@/common/config.js";
import moment from "moment";
import {
  get_ton_stake_config,
  get_user_detail,
  per_stake,
  bind_ton_tx_hash,
} from "@/api/goods";
import WithdrawInputDialog from "@/components/StakeTemp/withdrawInputDialog.vue";
import EditNicknameDialog from "@/components/StakeTemp/editNicknameDialog.vue";
import Subuser from "@/components/StakeTemp/subuser.vue";

moment.locale("en");
export default {
  name: "Stake",
  components: {
    Subuser,
    EditNicknameDialog,
    WithdrawInputDialog,
    InputDialog,
    BindCodeDialog,
    // UserProfileList,
    TonProfileList,
    ProgressInfo,
    LoadingDialog,
    // RewardHistoryDataTable,
    dataList,
  },
  mixins: [Login],
  data() {
    return {
      inputAmount: 0,
      isShowInputDialog: false,
      days: "",
      startingTime: "",
      tonStakeContract: null,
      usdtContract: null,
      tonContract: null,
      stTonContract: null,
      nftContract: null,
      tonPrice: 0,
      usdtBalance: 0,
      stakingTon: 0,
      stakeMilestone: 0,
      isInitTonSkateContract: false,
      loadingMessage: "",
      txHash: "",
      aprValue: 0,
      aprValueToShow: 0,
      stakeMilestoneMax: 1000000,
      isLoading: false,
      userDetail: null,
      showTipDialog: false,
      subUsers: null,
    };
  },
  created() {
    this.startingTime = moment("2023-02-01").format("YYYY-MM-DD");
    this.days = moment(this.startingTime).diff(
      moment(new Date().getTime()),
      "days"
    );
    if (this.days <= 0) {
      this.days = 0;
    }
    let date = new Date("2023-02-01 0:0:0"); //时间对象
    let startDay = date.getTime(); //转换成时间戳
    let now = Date.parse(new Date());
    if (startDay > now) {
      this.days = 0;
    }
    this.initData();
    getProvider();
    // let localWalletAddress = window.sessionStorage.getItem("walletAddress");
    // console.log("localWalletAddress", localWalletAddress);
    // if (localWalletAddress) {
    //   this.$store.commit("user/walletAddress", localWalletAddress);
    // }
    if (isMetaMaskInstalled()) {
      this.ethereumChange();
    }
  },
  watch: {
    walletAddress: {
      handler(newVal, oldVal) {
        console.log("watch walletAddress", newVal, oldVal);
        if (this.walletAddress.length === 0) {
          this.resetData();
        } else {
          if (!this.isInitTonSkateContract) {
            this.initData().then(() => {
              this.initTonSkateContract();
              this.getUserDetail();
            });
          }
        }
      },
    },
  },
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.walletAddress,
      userLevelRateConfig: (state) => state.user.userLevelRateConfig,
      userLevel: (state) => state.user.userLevel,
    }),
    getAddress: () => {
      return (val) => {
        let left = val.slice(0, 6);
        let right = val.slice(val.length - 4, val.length);
        return `${left}...${right}`;
      };
    },
  },
  methods: {
    handleSubusers(item) {
      // console.log("sub_user", item);
      // console.log("sub_user length", item.sub_user.length);
      let arr = [];
      if (item?.sub_user?.length > 0) {
        // console.log("sub_user length", item.sub_user.length);
        for (let i = 0; i < item?.sub_user?.length; i++) {
          const val = item?.sub_user[i];
          // console.log('const val = item?.sub_users[i];',val);
          // let color = val?.isProxy ? colorGreen.value : colorRed.value
          // let symbolImg = val?.isProxy ? greenImg : redImg
          let obj = {
            ...val,
            name: val.nickname,
            // symbol: 'image://' + symbolImg,
            // itemStyle: {
            //   color: color, // 节点背景颜色
            // },
          };
          if (val?.sub_user?.length > 0) {
            obj.children = this.handleSubusers(val);
          }
          arr.push(obj);
        }
      } else {
        // let color = item?.isProxy ? colorGreen.value : colorRed.value
        // let symbolImg = item?.isProxy ? greenImg : redImg
        // let obj = {
        //   ...item,
        //   name: item.nickname,
        //   // value: item.email,
        //   // symbol: 'image://' + symbolImg,
        //   itemStyle: {
        //     // color: color, // 节点背景颜色
        //   },
        // };
        // arr.push(obj);
      }
      return arr;
    },
    showEditNickname() {
      this.$refs.editNicknameDialogRef.isShow();
    },
    showBindCodeDialog() {
      this.$refs.bindCodeDialogRef.isShow();
    },
    async initData() {
      await get_ton_stake_config().then((res) => {
        res.data.forEach((item) => {
          if (item.name === "price") {
            this.tonPrice = item.value;
          }
          if (item.name === "milestone") {
            let percentage = item.value / this.stakeMilestoneMax;
            if (percentage > 0 && percentage < 0.01) {
              percentage = 0.01;
            }
            if (percentage >= 100) {
              percentage = 1;
            }
            this.stakeMilestone = percentage.toFixed(2) * 100;
          }
          if (item.name === "apr") {
            this.aprValue = item.value;
          }
        });
      });
    },
    resetData() {
      this.usdtBalance = 0;
      this.stakingTon = 0;
      this.loadingMessage = "";
      this.txHash = "";
      this.isLoading = false;
      this.userDetail = null;
      this.aprValueToShow = "0";
      this.aprValue = 0;
      this.$store.commit("user/userLevel", 0);
    },
    closeLoadingDialogRef() {
      this.$refs.loadingDialogRef.close();
    },
    goToViewEtherscan() {
      window.open(`${config.etherscanUrl}/tx/${this.txHash}`, "_blank");
    },
    ethereumChange() {
      window.ethereum.on("chainChanged", (networkIDString) => {
        console.log(
          "chainChanged",
          networkIDString,
          window.switchNetworkNumber
        );
        this.handleLogout();
      });
      window.ethereum.on("accountsChanged", (accounts) => {
        console.log("accountsChanged", accounts[0]);
        this.handleLogout();
      });
    },
    btnWithdraw() {
      if (this.isLoading) {
        this.$refs.loadingDialogRef.isShow();
        return;
      }
      this.$refs.withdrawInputDialogRef.isShow();
    },
    withdrawInputDialogSave(inputAmount, tonAddress) {
      if (!this.usdtContract || this.isLoading) {
        return;
      }
      if (inputAmount < 10) {
        this.tipMsg = this.$t("message.minWithdrawAmount");
        this.showTipDialog = true;
        return;
      }
      console.log("inputAmount", inputAmount);
      this.withdrawStTonHandle({
        decimal: config.TON_DECIMALS,
        amount: inputAmount,
        contractAddress: config.TON_STAKE_STTON,
        receivingAddress: config.RECEIVING_WALLET,
        userAddress: this.walletAddress,
        tonAddress: tonAddress,
      });
    },
    withdrawStTonHandle(res) {
      this.loadingMessage = this.$t("message.transactionSubmitted");
      this.$refs.loadingDialogRef.isShow();
      this.isLoading = true;
      this.txHash = "";
      recharge_platform_account(
        res,
        (txHash) => {
          this.loadingMessage = this.$t("message.transactionSuccessfully");
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: this.loadingMessage,
          });
          this.isLoading = false;

          releaseStake({
            tx_hash: txHash,
            address: this.walletAddress,
            ton_address: res.tonAddress,
          }).then((res) => {
            this.getUserDetail();
          });
        },
        (err) => {
          console.log("err", err);
          let text = "";
          if (err?.i18n) {
            text = this.$t("message." + err.i18n);
          } else {
            text = err || this.$t("navDrawer.transactionFail");
          }
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: text,
          });
          this.isLoading = false;
          this.btnToBuySwitch = false;
        },
        (txHash) => {
          this.loadingMessage = this.$t("message.transactionProcessing");
          releaseStake({
            tx_hash: txHash,
            address: this.walletAddress,
            ton_address: res.tonAddress,
          });
        },
        (txHash) => {
          this.txHash = txHash;
        }
      );
    },
    async initTonSkateContract() {
      console.log("initTonSkateContract");
      this.isInitTonSkateContract = true;
      this.usdtContract = await getContract(config.TON_STAKE_USDT, ContractAbi);
      this.stTonContract = await getContract(
        config.TON_STAKE_STTON,
        ContractAbi
      );
      this.nftContract = await getContract(
        config.NFT_CONTRACT,
        Erc721ContractAbi
      );
      if (this.stTonContract) {
        await this.getUserTonAmount();
      }
      // check team
      if (this.nftContract) {
        if (process.env?.VUE_APP_TEAM === "bidexGroup") {
          this.aprValueToShow = this.aprValue;
        } else {
          await this.getUserNft();
        }
      }
    },
    btnStake() {
      if (this.isLoading) {
        this.$refs.loadingDialogRef.isShow();
        return;
      }
      // if (process.env?.VUE_APP_TEAM !== "bidexGroup") {
      //   if (this.userLevel <= 0) {
      //     this.showTipDialog = true;
      //     return;
      //   }
      // }
      this.$refs.inputDialogRef.isShow();
    },
    inputDialogSave(inputAmount) {
      if (!this.usdtContract || this.isLoading) {
        return;
      }
      this.rechargePlatformAccount({
        decimal: config.USDT_DECIMALS,
        amount: inputAmount,
        contractAddress: config.TON_STAKE_USDT,
        receivingAddress: config.RECEIVING_WALLET,
        userAddress: this.walletAddress,
      });
    },
    rechargePlatformAccount(res) {
      this.loadingMessage = this.$t("message.transactionSubmitted");
      this.$refs.loadingDialogRef.isShow();
      this.isLoading = true;
      this.txHash = "";
      recharge_platform_account(
        res,
        (txHash) => {
          this.loadingMessage = this.$t("message.transactionSuccessfully");
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: this.loadingMessage,
          });
          this.isLoading = false;

          bind_ton_tx_hash({
            tx_hash: txHash,
          }).then((res) => {
            this.getUserDetail();
          });
        },
        (err) => {
          console.log("err", err);
          let text = "";
          if (err?.i18n) {
            text = this.$t("message." + err.i18n);
          } else {
            text = err || this.$t("navDrawer.transactionFail");
          }
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: text,
          });
          this.isLoading = false;
          this.btnToBuySwitch = false;
        },
        (txHash) => {
          this.loadingMessage = this.$t("message.transactionProcessing");
          this.txHash = txHash;
          this.toPerStake(txHash);
        },
        (message) => {
          // this.loadingMessage = this.$t("message." + message.i18n);
        }
      );
    },
    toPerStake(txHash, tryTimes = 0) {
      per_stake({
        tx_hash: txHash,
      })
        .then((res) => {})
        .catch((e) => {
          if (tryTimes < 3) {
            tryTimes++;
            this.toPerStake(txHash, tryTimes);
          }
        });
    },
    handleLogout() {
      this.$refs.loadingDialogRef.close();
      this.$refs.inputDialogRef.close();
      this.$refs.withdrawInputDialogRef.close();
      this.isInitTonSkateContract = false;
      this.txHash = "";
      this.isLoading = false;
      this.$store.dispatch("user/logout");
    },
    async getUserTonAmount() {
      let balance = await this.stTonContract.balanceOf(this.walletAddress);
      console.log("balance", balance);
      if (balance) {
        // Keep 4 decimal places  9-4=5
        let power = BigNumber.from(10).pow(parseInt(config.TON_DECIMALS - 4));
        this.stakingTon = BigNumber.from(balance).div(power).toString() / 10000;
      }
    },
    async getUserNft() {
      const maxLevel = 7;
      let userLevel = this.userLevel;
      for (let i = maxLevel; i > 0; i--) {
        await this.nftContract
          .balanceOf(this.walletAddress, i)
          .then((res) => {
            if (res && res.gt(BigNumber.from(userLevel))) {
              userLevel = i;
            }
          })
          .catch((err) => {
            console.log("getUserNft err", err);
          });
        if (userLevel > 0) {
          this.aprValueToShow = (
            parseFloat(this.aprValue) *
            (this.userLevelRateConfig[userLevel] / 100)
          ).toString();
          break;
        }
      }
      this.$store.commit("user/userLevel", userLevel);
    },
    // async checkAllowance() {
    //   return await this.usdtContract.allowance(
    //     this.walletAddress,
    //     TON_STAKE_LOGIC
    //   );
    // },
    getUserDetail() {
      get_user_detail({
        address: this.walletAddress,
        // address: "0xF499938D18EB079357FED7dFB71f524116CA5855",
      })
        .then((res) => {
          // console.log("getUserDetail res", res);
          if (res?.data) {
            this.aprValueToShow = (
              parseFloat(res.data?.apr / 100) || 0
            ).toFixed(2);
            this.userDetail = res.data;
            this.usdtBalance = res.data.usdt_num / 100;
            this.$store.commit("user/setUserInfo", res.data);
            console.log("res.data", res.data);
            this.subUsers = {
              name: res.data.nickname,
              nickname: res.data.nickname,
              children: this.handleSubusers(res.data),
            };
            // if ((res.data?.cosmos_addr ?? "") === "") {
            //   this.showBindCodeDialog();
            // }
          }
        })
        .catch((e) => {
          console.log("getUserDetail err", e);
        });
    },
    closeShowTipDialog() {
      this.showTipDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.stake-container {
  width: 100%;
  height: 100%;
  color: #333333;
  background-color: #fafafa;
  padding-bottom: 100px;
  box-sizing: border-box;
}

.list-container {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

.data-list-container {
  margin-top: 36px;
}

.user-profile-list-container {
  margin-top: 36px;
}

.ton-profile-list-container {
  margin-top: 20px;
}

.date-info-box {
  font-size: 14px;
  margin-top: 40px;
  position: relative;

  .date-value {
    font-weight: bold;
    margin-left: 4px;
  }
}

.bright-2 {
  width: 72.05px;
  height: 59.6px;
  background: #7eb2ff;
  filter: blur(50.5px);
  border-radius: 100px;
  position: absolute;
  left: 0;
  top: -16px;
}

.progress-info-container {
  margin-top: 60px;
}

.nav-box {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: rgb(46 46 68 / 30%);
  border-bottom: 2px solid #303249;
}

.logo {
  width: 36px;
  height: 36px;
  display: block;
}

.invite-code {
  font-weight: 500;
  font-size: 16px;
  margin-right: 10px;
  width: 100px;
}

.address {
  font-weight: 500;
  color: #ffffff;
  padding: 0 20px;
  background-color: #2a293c;
  border-radius: 20px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
}

.btn-connect {
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  color: #ffffff;
  font-size: 15px;
  border-radius: 20px;
  background-color: #5721f0;
  font-weight: 500;
}

.loading-box {
  margin: 30px 0;
}

.icon-upload {
  width: 80px;
  height: 80px;
  display: block;
  margin: 0 auto 10px;
}

.loading-text {
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.loading-view-text {
  font-size: 16px;
  color: #f9669a;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  text-decoration: underline;
}

.btn-close {
  width: 88px;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  border-radius: 20px;
  color: #ffffff;
  background-color: #f9669a;
  font-weight: 500;
  text-align: center;
  margin: 60px auto 0;
}

.show-tip-dialog {
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  position: fixed;
  left: 0;
  top: 0;
}

.show-tip-content {
  width: 240px;
  color: #1fc7d4;
  background-color: #423a6c;
}

.show-tip-text {
  width: 240px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #ffffff;
}
</style>
