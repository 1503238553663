<template>
  <div>
    <ul class="data-list">
      <li class="data-list-item" v-for="(i, k) in getInfoList" :key="k">
        <div class="data-list-item-title">
          <img class="data-list-item-title-icon" alt="icon" :src="i.iconName" />
          <div>
            <div class="data-list-item-title-name">{{ i.title }}</div>
            <div class="data-list-item-title-sub">{{ i.subTitle || "" }}</div>
          </div>
        </div>
        <div class="data-list-item-val">
          {{ getValue(k, i.value) }}
        </div>
      </li>
    </ul>
    <div class="btn-stake-box">
      <!--        <div-->
      <!--          class="btn-stake cursor"-->
      <!--          @click="btnWithdraw"-->
      <!--          style="float: right; margin-right: 10px"-->
      <!--          v-if="walletAddress && walletAddress.length > 1"-->
      <!--        >-->
      <!--          Withdraw-->
      <!--        </div>-->
      <div
        class="btn-connect"
        style="width: 100%; float: right"
        @click="btnStake"
        v-if="walletAddress && walletAddress.length > 1"
      >
        Stake
      </div>
      <div class="btn-connect cursor" v-else @click="connectWallet">
        Connect Wallet
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
let typeArr = [];
export default {
  name: "DataList",
  components: {},
  props: {
    stakingTon: {
      type: [Number, String],
      default: 0,
    },
    usdtBalance: {
      type: [Number, String],
      default: 0,
    },
    userDetail: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    userDetail: {
      handler() {
        this.initInfoList(0, parseFloat(this.userDetail?.usdt_num / 100) || 0);
        this.initInfoList(
          1,
          parseFloat(this.userDetail?.usdt_num / 100 / 0.05) || "0"
        );
        this.initInfoList(
          2,
          parseFloat((this.userDetail?.usdt_num / 100 / 0.05) * 0.5) || "0"
        );
        this.initInfoList(
          3,
          parseFloat((this.userDetail?.usdt_num / 100 / 0.05) * 0.5) || "0"
        );
        // this.initInfoList(3, this.userDetail?.this_mouth_income ?? "0");
        // this.initInfoList(4, this.userDetail?.total_income ?? "0");
      },
      deep: true,
    },
    stakingTon() {
      this.initInfoList(0, this.stakingTon);
    },
    // usdtBalance() {
    //   this.initInfoList(0, this.usdtBalance);
    // },
  },
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.walletAddress,
      userLevelRateConfig: (state) => state.user.userLevelRateConfig,
      userLevel: (state) => state.user.userLevel,
      userInfo: (state) => state.user.userInfo,
    }),
    getValue() {
      return (index, value) => {
        if (typeArr.includes(index)) {
          value = Number(value).toFixed(4);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };
    },
    dailyValue() {
      return parseFloat(this.userDetail?.reward_rate / 100) || 0;
    },
    getInfoList() {
      return this.infoList.filter((i) => i.show);
    },
  },
  data() {
    return {
      infoList: [
        {
          title: "USDT",
          value: 0,
          subTitle: "usdt",
          show: true,
          iconName: require("@/assets/image/usdt.png"),
        },
        {
          title: "Rabbit",
          subTitle: "total",
          value: "0",
          show: true,
          iconName: require("@/assets/image/rabbit.png"),
        },
        {
          title: "Rabbit",
          value: "0",
          subTitle: "locked",
          // type: "Daily",
          show: true,
          iconName: require("@/assets/image/rabbit.png"),
        },
        {
          title: "Rabbit",
          value: "0",
          subTitle: "balance",
          // type: "Daily",
          show: true,
          iconName: require("@/assets/image/rabbit.png"),
        },
        // {
        //   title: "Total Cumulative Earnings",
        //   value: "0",
        //   show: true,
        //   iconName: require("@/assets/image/tip.png"),
        // },
      ],
    };
  },
  created() {},
  methods: {
    initInfoList(type, value) {
      this.$set(this.infoList[type], "value", value);
    },
    btnStake() {
      this.$emit("btnStake");
    },
    connectWallet() {
      this.$emit("connectWallet");
    },
    btnWithdraw() {
      console.log("btnWithdraw");
      this.$emit("btnWithdraw");
    },
  },
};
</script>

<style scoped lang="scss">
.data-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: unset;

  &-item {
    width: 30%;
    height: 160px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 12px;
    color: #333333;
    background-color: #ffffff;
    box-shadow: 0 10px 24px rgba(217, 169, 255, 0.25);
    margin-right: 4%;
    margin-bottom: 30px;
    position: relative;

    &-val {
      position: absolute;
      left: 10px;
      bottom: 10px;
      color: #000000;
    }

    &-title {
      display: flex;
      &-icon {
        width: 40px;
        height: 40px;
        display: block;
        margin-right: 10px;
      }

      &-sub {
        color: #b7b7b7;
        font-size: 16px;
        line-height: 16px;
      }

      &-name {
        font-size: 20px;
        line-height: 20px;
        color: #000000;
      }
    }
  }
}

.data-list-item:nth-child(3n) {
  margin-right: 0;
}

.btn-stake-box {
  padding: 30px 16px;
  display: flex;
  justify-content: end;
}

.btn-stake {
  width: 154px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9669a;
  color: #ffffff;
  border-radius: 20px;
  cursor: pointer;
}

.btn-stake-disable {
  background-color: rgba(250, 176, 191, 0.8);
  cursor: not-allowed;
}

.btn-connect {
  padding: 0 20px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  color: #ffffff;
  border-radius: 20px;
  background-color: #f9669a;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .data-list-item {
    width: 30%;
  }
}

@media screen and (max-width: 860px) {
  .data-list-item {
    width: 46%;
  }

  .data-list-item:nth-child(3n) {
    margin-right: 4%;
  }
}
//@media screen and (max-width: 500px) {
//  .data-list {
//    justify-content: center;
//  }
//  .data-list-item {
//    width: 50%;
//    margin-right: 0;
//  }
//
//  .data-list-item:nth-child(3n) {
//    margin-right: 0;
//  }
//}
</style>
