<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent scrollable max-width="500px">
      <v-card class="dialog-card-box">
        <v-card-title>Please enter binding information</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="connect-box">
          <div class="input-item-box">
            <div class="input-item-title">invite code：</div>
            <v-text-field
              hide-details="auto"
              v-model="inviteCode"
              class="input-item"
              filled
              rounded
              dense
              color="#333333"
              type="string"
              hide-spin-buttons
              @change="checkInviteCode"
              @blur="checkInviteCode"
              @keydown.enter="checkInviteCode"
              @input="changeInviteCode"
            ></v-text-field>
          </div>
          <!--          <div class="input-item-box">-->
          <!--            <div class="input-item-title">cosmos address：</div>-->
          <!--            <v-text-field-->
          <!--              hide-details="auto"-->
          <!--              v-model="cosmosAddress"-->
          <!--              class="input-item"-->
          <!--              filled-->
          <!--              rounded-->
          <!--              dense-->
          <!--              color="#333333"-->
          <!--              type="string"-->
          <!--              hide-spin-buttons-->
          <!--              @input="changeCosmosAddress"-->
          <!--            ></v-text-field>-->
          <!--          </div>-->
        </v-card-text>
        <v-card-actions class="justify-end">
          <div class="btn-confirm cursor" @click="save">confirm</div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { bind } from "@/api/user.js";
import { mapState } from "vuex";
export default {
  name: "bindCodeDialog",
  data() {
    return {
      dialog: false,
      inviteCode: "",
      cosmosAddress: "",
    };
  },
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.walletAddress,
    }),
  },
  methods: {
    isShow() {
      this.inviteCode = "";
      this.cosmosAddress = "";
      this.dialog = true;
    },
    save() {
      this.cosmosAddress = "123";
      if (!this.inviteCode) {
        return;
      }
      bind({
        address: this.walletAddress,
        cosmos_address: this.cosmosAddress,
        invite_code: this.inviteCode,
      })
        .then((res) => {
          this.$emit("save");
          if (res?.message === "success") {
            this.dialog = false;
          }
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: res?.message ?? "success",
          });
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: e.message || e || this.$t("network.tryAgain"),
          });
        });
    },
    changeCosmosAddress() {},
    changeInviteCode() {},
    checkInviteCode() {},
  },
};
</script>

<style scoped lang="scss">
.connect-box {
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.dialog-card-box {
  color: #333333;
  background-color: #ffffff;
  border-radius: 10px;
}

.btn-confirm {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  border-radius: 20px;
  background-color: #f9669a;
  font-weight: 500;
  padding: 0 20px;
  color: #ffffff;
}

.btn-cancel {
  color: rgba(0, 0, 0, 0.6);
  padding: 0 10px;
  margin-right: 10px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;
}

.btn-cancel:hover {
  color: #cccccc;
}

.input-item {
  color: #333333 !important;
  caret-color: #333333 !important;
}

.input-item-box {
  width: 100%;
  margin-top: 20px;
}

.input-item-title {
  font-size: 17px;
  color: #333333;
  line-height: 30px;
  padding-left: 20px;
}
</style>
