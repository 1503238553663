// 4: Rinkeby 1: Ethereum 5: Goerli 42: Kovan 56: BSC 97: BSC Testnet 137: Polygon 80001: Polygon Testnet
const switchNetworkNumber = 5;
const switchNetworkParam = {
  chainId: "0x5",
  chainName: "Ethereum Mainnet",
  nativeCurrency: {
    name: "GoerliETH",
    symbol: "GoerliETH",
    decimals: 18,
  },
  rpcUrls: ["https://goerli.infura.io/v3"],
  blockExplorerUrls: ["https://goerli.etherscan.io"],
};

const USDT_DECIMALS = 18;
const TON_DECIMALS = 9;
const etherscanUrl = "https://goerli.etherscan.io";

const TON_STAKE_USDT = "0xDc6b04c8F57AC281e05B9FC3c1a3c63b4857872E"; // contract
const TON_STAKE_STTON = "0x24452702d345e31Ae754bb21caE91aB99732D2Af"; // contract
const RECEIVING_WALLET = "0x0483C788E4927A4f3a832F68D86391FE827b1E18"; // wallet
const NFT_CONTRACT = "0x8b4913e269c4923e9ba23819636789915143ade4"; // contract

module.exports = {
  switchNetworkNumber,
  switchNetworkParam,
  USDT_DECIMALS,
  etherscanUrl,
  TON_DECIMALS,
  TON_STAKE_USDT,
  TON_STAKE_STTON,
  NFT_CONTRACT,
  RECEIVING_WALLET,
};
