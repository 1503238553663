<template>
  <div class="echarts-box">
    <div id="myEcharts" :style="{ width: '90%', height: '500px' }"></div>
  </div>
</template>
<script>
import Login from "@/views/script/login";
import * as echarts from "echarts";
// import redImg from ''

export default {
  name: "subuser",
  components: {},
  mixins: [Login],
  data() {
    return {
      charEle: null,
    };
  },
  props: {
    subUserData: {
      type: [Object],
    },
  },
  created() {},
  mounted() {
    console.log("this.subUserData", this.subUserData);
    this.InitEchart();
  },
  watch: {
    walletAddress: {
      handler(newVal, oldVal) {
        console.log("watch walletAddress", newVal, oldVal);
        if (this.walletAddress.length === 0) {
          this.resetData();
        } else {
          if (!this.isInitTonSkateContract) {
            this.initData().then(() => {
              this.initTonSkateContract();
              this.getUserDetail();
            });
          }
        }
      },
    },
  },
  computed: {},
  methods: {
    InitEchart() {
      console.log(JSON.stringify(this.subUserData));
      // let charEle = document.getElementById("myEcharts");
      this.charEle = document.getElementById("myEcharts");
      let charEle = this.charEle;
      let charEch = echarts.init(charEle);
      let option = {
        tooltip: {
          trigger: "item",
          triggerOn: "click",
          enterable: true,
          borderWidth: 3,
        },
        series: [
          {
            edgeShape: "curve", // 线展示为折线
            type: "tree",
            data: [this.subUserData],
            left: "2%",
            right: "2%",
            top: "8%",
            bottom: "20%",
            // symbol: 'circle',
            symbol: "image:///static/image/img.png",
            orient: "vertical", // 从上到下布局
            initialTreeDepth: 2, // 默认首次展开几层子级
            symbolSize: 60, // 空心圆的大小
            roam: true,
            edgeSymbol: ["circle", "arrow"], // 线的样式
            edgeSymbolSize: [4, 10],
            lineStyle: {
              width: 2, //线宽度
              color: "#68b0e9", // 线颜色
            },
            itemStyle: {
              // color: 'red', // 节点背景颜色
            },
            textStyle: {
              // fontSize: 20,
            },
            label: {
              show: true,
              position: "bottom", // inside
              distance: 10, // 文本和节点图片之间距离
              verticalAlign: "middle",
              align: "center",
              fontSize: 20,
              color: "#fff", // 文字颜色
              textBorderColor: "inherit", // 文字描边颜色
              textBorderWidth: 3, // 文字描边宽度
              formatter: function (params) {
                let data = params?.data ?? {};
                return `${data?.nickname}`;
              },
            },
            leaves: {
              label: {
                position: "bottom",
                verticalAlign: "middle",
                align: "center",
                fontSize: 20,
              },
            },
            emphasis: {
              // 高亮代码
              focus: "series",
              blurScope: "coordinateSystem",
              itemStyle: {
                // color: 'rgba(255, 153, 0, 1)'
              },
              label: {
                // textBorderColor: colorOrange.value,
              },
            },
            expandAndCollapse: true, // 子树折叠和展开的交互，默认打开
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      };
      charEch.setOption(option);

      charEch.on("click", function (params) {
        // 控制台打印数据的名称
        console.log(params.name, params?.componentType);
        if (params.componentType === "markPoint") {
          // 点击到了 markPoint 上
          if (params.seriesIndex === 5) {
            // 点击到了 index 为 5 的 series 的 markPoint 上。
          }
        } else if (params.componentType === "series") {
          if (params.seriesType === "tree") {
            if (params.dataType === "edge") {
              // 点击到了 graph 的 edge（边）上。
            } else {
              // 事件处理代码书写位置
              // 点击到了 graph 的 node（节点）上。
              // showPanel.value = true
              // const el: any = document.querySelector('.' + mainPanel)
              // let clientX = params.event?.event?.offsetX ?? 0
              // let clientY = params.event?.event?.offsetY ?? 0
              // let left = clientX + 60 + 'px'
              // let top = clientY + 60 + 'px'
              // el.style.left = left
              // el.style.top = top
            }
          }
        }
      });
      // charEch.on('click', 'series', function(params) {
      //   console.log('---', params)
      // });
      charEch.getZr().on("click", function (event) {
        // 没有 target 意味着鼠标/指针不在任何一个图形元素上，它是从“空白处”触发的。
        if (!event.target) {
          // 点击在了空白处，做些什么。
          // showPanel.value = false
        }
      });

      charEch.on("mouseover", { seriesId: 1 }, function (params) {
        console.log("mouseover", params);
      });

      window.addEventListener("resize", function () {
        console.log("resize");
        charEch.resize();
        // showPanel.value = false
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
