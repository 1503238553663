import { BigNumber, ethers } from "ethers";
import ContractAbi from "@/common/abi/ContractAbi.json";
import { getProvider } from "@/utils/index.js";
let signer;
let refresh = 0;
let timeOut = null;
let transactionStatus = 0;
let isInCheckPadding = false;
export const MaxUint256 = ethers.constants.MaxUint256;
// const usdtContractWalletAddress = "0xe27658a36cA8A59fE5Cc76a14Bde34a51e587ab4";
import config from "@/common/config.js";

export function Connect2MetamaskProcess() {
  return new Promise((resolve, reject) => {
    refresh = 0;
    CheckAndSwitchChain()
      .then(() => {
        requestAccounts()
          .then(() => {
            signer = window.provider.getSigner();
            signer
              .getAddress()
              .then((address) => {
                resolve(address);
              })
              .catch((error) => {
                console.error("request signer.getAddress err:\n", error);
                reject(error || "Install and log in to MetaMask");
              });
          })
          .catch((error) => {
            console.error("request requestAccounts catch:\n", error);
            reject(error);
          });
      })
      .catch((error) => {
        console.error("request check and switch chain err:\n", error);
        reject(error || "Install and log in to MetaMask");
      });
  });
}

function CheckAndSwitchChain() {
  return new Promise((resolve, reject) => {
    if (!window.provider) {
      getProvider();
    }
    if (window.provider) {
      window.provider
        .getNetwork()
        .then((network) => {
          if (String(network.chainId) === String(config.switchNetworkNumber)) {
            resolve();
          } else {
            if (String(refresh) === "0") {
              refresh = 1;
              switchEthereumChain()
                .then(() => {
                  resolve();
                })
                .catch((err) => {
                  if (err.code === 4902) {
                    addEthereumChain()
                      .then(() => {
                        resolve();
                      })
                      .catch((err) => {
                        console.error(
                          "request add ethereum chain catch\n",
                          err
                        );
                        reject(err);
                      });
                  } else {
                    console.error("request add ethereum chain err\n", err);
                    reject(err);
                  }
                });
            } else {
              refresh = 0;
              console.error("Please switch to the correct network");
              reject("Please switch to the correct network");
            }
          }
        })
        .catch((err) => {
          console.error("request window.provider.getNetwork catch:\n", err);
          reject(err);
        });
    } else {
      console.error("Please install MetaMask");
      reject("Please install MetaMask");
    }
  });
}

function requestAccounts() {
  return new Promise((resolve, reject) => {
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((res) => {
        if (res.length > 0) {
          resolve();
        } else {
          console.error("Please log in to MetaMask");
          reject("Please log in to MetaMask");
        }
      })
      .catch((err) => {
        console.error("request eth_requestAccounts catch:\n", err);
        reject(err);
      });
  });
}

async function addEthereumChain() {
  return await window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [config.switchNetworkParam],
  });
}

async function switchEthereumChain() {
  await window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [
      {
        chainId: config.switchNetworkParam.chainId,
      },
    ],
  });
}

export function confirm_transaction_receipt(hash) {
  return new Promise((resolve, reject) => {
    window.provider
      .getTransactionReceipt(hash)
      .then(function (receipt) {
        console.log(
          "order receipt result ok \n",
          receipt?.status,
          receipt?.status == 1
        );
        if (receipt && receipt.status && receipt.status == 1) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((e) => {
        console.error("order receipt result err \n", e);
        reject(e);
      });
  });
}

export async function recharge_platform_account(
  item,
  call_back,
  call_catch,
  call_hash,
  loading_tip
) {
  let { contractAddress, amount, decimal, receivingAddress, userAddress } =
    item;
  if (!window.provider) {
    await CheckAndSwitchChain()
      .then(function () {
        signer = window.provider.getSigner();
      })
      .catch((error) => {
        console.error(
          "CheckAndSwitchChain err:\n",
          error || "Install and log in to MetaMask"
        );
        throw Error(error || "Install and log in to MetaMask");
      });
  } else {
    signer = window.provider.getSigner();
  }
  try {
    loading_tip({
      i18n: "loadingTip1",
    });
    amount = BigNumber.from(String(amount)).toString();
    let power = BigNumber.from(10).pow(parseInt(decimal));
    const _amount = BigNumber.from(amount).mul(power).toString();
    const gasPrice = await window.provider.getGasPrice();
    const gas_price_min = BigNumber.from(String(3000000000)).toString();
    const gas_limit = BigNumber.from(String(210000)).toString();
    let gas_price = BigNumber.from(gasPrice).div(3).add(gasPrice).toString(); // 1.3 multiple gas price
    // gas_price < gas_price_min
    if (BigNumber.from(gas_price).lt(gas_price_min)) {
      gas_price = gas_price_min;
    }
    const contract = new ethers.Contract(contractAddress, ContractAbi, signer);
    let balance = await contract.balanceOf(userAddress);
    // balance < _amount
    if (BigNumber.from(balance).lt(_amount)) {
      console.error("Order err \n", "balance < amount, insufficient balance");
      call_catch({
        i18n: "insufficientBalance",
      });
      return;
    }
    loading_tip({
      i18n: "loadingTip2",
    });
    let _receivingAddress =
      String(receivingAddress).length > 0
        ? receivingAddress.slice(
            receivingAddress.length - 4,
            receivingAddress.length
          )
        : "";
    console.log("send transfer\n", {
      receivingAddress: _receivingAddress,
      amount: _amount,
      gasLimit: gas_limit,
      gasPrice: gas_price,
    });
    contract
      .transfer(receivingAddress, _amount, {
        gasLimit: gas_limit,
        gasPrice: gas_price,
      })
      .then(function (result) {
        let hash = result.hash;
        try {
          call_hash(hash);
        } catch (e) {
          console.error("call_hash\n", e);
        }
        transactionStatus = 0;
        loading_tip({
          i18n: "loadingTip3",
        });
        timeOut = null;
        timeOut = setInterval(() => {
          if (transactionStatus === 1) {
            clearInterval(timeOut);
            timeOut = null;
          } else {
            isInCheckPadding = false;
            check_transaction_receipt(hash, call_back, call_catch);
          }
        }, 1500);
      })
      .catch((err) => {
        console.error("request err. contract transfer err:\n", err);
        call_catch(err);
      });
  } catch (e) {
    console.error("contract err:", e);
    call_catch(e);
  }
}

export function check_transaction_receipt_single(hash, call_back, call_catch) {
  transactionStatus = 0;
  timeOut = setInterval(() => {
    if (transactionStatus === 1) {
      clearInterval(timeOut);
      timeOut = null;
    } else {
      isInCheckPadding = false;
      check_transaction_receipt(hash, call_back, call_catch);
    }
  }, 1000);
}

function check_transaction_receipt(hash, call_back, call_catch) {
  if (isInCheckPadding) return;
  isInCheckPadding = true;
  confirm_transaction_receipt(hash)
    .then((res) => {
      console.log("receipt", res);
      if (res) {
        transactionStatus = 1;
        isInCheckPadding = false;
        call_back(hash);
        try {
          clearInterval(timeOut);
          timeOut = null;
        } catch (e) {
          timeOut = null;
        }
      } else {
        isInCheckPadding = false;
      }
    })
    .catch((e) => {
      console.error("confirm transaction receipt err:\n", e);
      isInCheckPadding = false;
      call_catch(e);
    });
}
