const switchNetworkNumber = 56;
const switchNetworkParam = {
  chainId: "0x38",
  chainName: "Binance Smart Chain Mainnet",
  nativeCurrency: {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  },
  rpcUrls: [
    "https://bsc-dataseed3.ninicoin.io",
    "https://bsc-dataseed3.defibit.io",
    "https://bsc-dataseed3.binance.org",
    "https://bsc-dataseed.binance.org",
    "https://bsc-dataseed2.binance.org",
    "https://bsc-dataseed1.ninicoin.io",
    "https://bsc-dataseed2.ninicoin.io",
    "https://bsc-dataseed4.binance.org",
    "https://bsc-dataseed1.defibit.io",
  ],
  blockExplorerUrls: ["https://bscscan.com"],
};

const USDT_DECIMALS = 18;
const TON_DECIMALS = 9;
const etherscanUrl = "https://bscscan.com";

const TON_STAKE_USDT = "0x55d398326f99059fF775485246999027B3197955"; // contract
const TON_STAKE_STTON = "0x40d0c3683cDeA61Ef4B029B531A5A0e872E32db7"; // contract
const RECEIVING_WALLET = "0xeDFFeAD48F4643143E77D5367039F8CEb9eAE1f2"; // wallet
const NFT_CONTRACT = "0xAE64791E2852FD5ccCFd5cA94A0D07ada861A8D4"; // contract

module.exports = {
  switchNetworkNumber,
  switchNetworkParam,
  USDT_DECIMALS,
  etherscanUrl,
  TON_DECIMALS,
  TON_STAKE_USDT,
  TON_STAKE_STTON,
  NFT_CONTRACT,
  RECEIVING_WALLET,
};
