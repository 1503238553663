import request from "./request";

export function get_goods(data) {
  return request({
    url: "/api/v1/get_goods",
    method: "get",
    params: data,
  });
}

export function get_upgrade_goods(data) {
  return request({
    url: "/api/v1/get_upgrade_goods",
    method: "get",
    params: data,
  });
}

export function create_order(data) {
  return request({
    url: "/api/v1/create_order",
    method: "put",
    data,
  });
}

export function bind_order(data) {
  return request({
    url: "/api/v1/bind_order",
    method: "post",
    data,
  });
}

export function withdraw(data) {
  return request({
    url: "/api/v1/withdraw",
    method: "put",
    data,
  });
}

export function recharge(data) {
  return request({
    url: "/api/v1/recharge",
    method: "put",
    data,
  });
}

export function per_pay(data) {
  return request({
    url: "/api/v1/per_pay",
    method: "post",
    data,
  });
}

export function per_stake(data) {
  return request({
    url: "/api/v1/per_stake",
    method: "get",
    params: data,
  });
}

export function get_ton_stake_config(data) {
  return request({
    url: "/api/v1/disc",
    method: "get",
    params: data,
  });
}

export function get_user_detail(data) {
  return request({
    url: "/api/v1/user",
    method: "get",
    params: data,
  });
}

export function bind_ton_tx_hash(data) {
  return request({
    url: "/api/v1/bind_hash",
    method: "post",
    data,
  });
}
