<template>
  <ul class="profile-list-box">
    <!--    <li class="info-item color-orange">-->
    <!--      <div class="info-item-title">TIA price</div>-->
    <!--      <div class="info-value">-->
    <!--        <span class="icon-line"></span-->
    <!--        >{{ Number(tonPrice).toFixed(4) }}&nbsp;USDT-->
    <!--      </div>-->
    <!--    </li>-->
    <!--    <li class="info-item color-pink">-->
    <!--      <div class="info-item-title info-item-title-info">-->
    <!--        APR-->
    <!--        <img class="icon-info" alt="info" src="@/assets/image/icon_info.png" />-->
    <!--        &lt;!&ndash;        <introduce-text class="info-content"></introduce-text>&ndash;&gt;-->
    <!--      </div>-->
    <!--      <div class="info-value">-->
    <!--        <span class="icon-line"></span-->
    <!--        ><span>{{ Number(aprValue).toFixed(2) }}%</span>-->
    <!--      </div>-->
    <!--    </li>-->
    <!--    <li class="info-item color-blue">-->
    <!--      <div class="info-item-title">Chain</div>-->
    <!--      <div class="info-value">-->
    <!--        <span class="icon-line"></span>BSC &lt;=&gt; TIA-->
    <!--      </div>-->
    <!--    </li>-->
    <li class="info-item color-gray" style="padding-right: 30px">
      <div class="info-item-title">URL</div>
      <div class="info-value">
        <img
          alt="Vue logo"
          src="../../assets/image/29140.png"
          style="width: 35px"
        />
        <a href="https://rabbitdao.xyz/" style="color: #f9669a">
          https://rabbitdao.xyz/</a
        >
      </div>
    </li>
  </ul>
</template>

<script>
// import IntroduceText from "@/components/StakeTemp/introduceText.vue";
export default {
  name: "TonProfileList",
  props: {
    tonPrice: {
      type: [Number, String],
      default: 0,
    },
    aprValue: {
      type: [Number, String],
      default: 0,
    },
  },
  components: {
    // IntroduceText,
  },
  data: function () {
    return {};
  },
};
</script>

<style scoped lang="scss">
.profile-list-box {
  width: 100%;
  border: 2px solid #ffffff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 10px 24px rgba(217, 169, 255, 0.25);
  background-color: #ffffff;

  .color-orange {
    color: #ff5c00;
    .icon-line {
      background-color: #ff5c00;
    }
  }

  .color-pink {
    color: #e33283;
    .icon-line {
      background-color: #e33283;
    }
  }

  .color-blue {
    color: #589bff;
    .icon-line {
      background-color: #589bff;
    }
  }

  .color-gray {
    color: #5fa47b;
    .icon-line {
      background-color: #5fa47b;
    }
  }

  .info-item {
    position: relative;
    .info-value {
      color: #f9669a;
      font-weight: bold;
      margin-top: 12px;
      font-size: 16px;
      height: 24px;
      display: flex;
      align-items: center;
    }

    .icon-line {
      width: 4px;
      height: 14px;
      border-radius: 4px;
      display: inline-block;
      margin-right: 6px;
    }

    .info-item-title {
      height: 22px;
      line-height: 22px;
    }

    .info-item-title-info:hover {
      .icon-info {
        opacity: 1;
      }

      .info-content {
        display: block;
      }
    }

    .icon-info {
      width: 12px;
      height: 12px;
      margin-left: 3px;
      display: inline-block;
      opacity: 0.5;
    }

    .info-content {
      display: none;
      position: absolute;
      left: 70px;
      top: -4px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .profile-list-box {
    display: block;

    .info-item {
      display: inline-block;
      width: 50%;
    }
    .info-item:nth-child(0) {
      margin-bottom: 20px;
    }
    .info-item:nth-child(1) {
      margin-bottom: 20px;
    }
  }
}
</style>
