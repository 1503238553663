<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="500px" :persistent="true">
      <v-card class="dialog-card-box">
        <v-card-title>Confirm Transaction</v-card-title>
        <v-divider></v-divider>
        <slot></slot>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "loadingDialog",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    isShow() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-card-box {
  color: #333333;
  background-color: #ffffff;
  border-radius: 10px;
}
</style>
