<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="500px">
      <v-card class="dialog-card-box">
        <v-card-title>Please enter new nickname</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="connect-box">
          <div class="input-item-box">
            <div class="input-item-title">nickname：</div>
            <v-text-field
              hide-details="auto"
              v-model="nickname"
              class="input-item"
              filled
              rounded
              dense
              color="#333333"
              type="string"
              hide-spin-buttons
              @change="checkNickname"
              @blur="checkNickname"
              @keydown.enter="checkNickname"
              @input="changeNickname"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <div class="btn-confirm cursor" @click="save">confirm</div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { update_nickname } from "@/api/user.js";
import { mapState } from "vuex";
export default {
  name: "editNicknameDialog",
  data() {
    return {
      dialog: false,
      nickname: "",
    };
  },
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.walletAddress,
    }),
  },
  methods: {
    isShow() {
      this.nickname = "";
      this.dialog = true;
    },
    save() {
      if (!this.nickname) {
        return;
      }
      update_nickname({
        address: this.walletAddress,
        nickname: this.nickname,
      })
        .then((res) => {
          this.$emit("save");
          if (res?.message === "success") {
            this.dialog = false;
          }
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: res?.message ?? "success",
          });
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: e.message || e || this.$t("network.tryAgain"),
          });
        });
    },
    changeNickname() {},
    checkNickname() {},
  },
};
</script>

<style scoped lang="scss">
.connect-box {
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.dialog-card-box {
  color: #333333;
  background-color: #ffffff;
  border-radius: 10px;
}

.btn-confirm {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  border-radius: 20px;
  background-color: #f9669a;
  font-weight: 500;
  padding: 0 20px;
  color: #ffffff;
}

.btn-cancel {
  color: rgba(0, 0, 0, 0.6);
  padding: 0 10px;
  margin-right: 10px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;
}

.btn-cancel:hover {
  color: #cccccc;
}

.input-item {
  color: #333333 !important;
  caret-color: #333333 !important;
}

.input-item-box {
  width: 100%;
  margin-top: 20px;
}

.input-item-title {
  font-size: 17px;
  color: #333333;
  line-height: 30px;
  padding-left: 20px;
}
</style>
