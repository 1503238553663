<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="500px">
      <v-card class="dialog-card-box">
        <v-card-title></v-card-title>
        <v-divider></v-divider>
        <v-card-text style="white-space: pre-line; color: white">
          下記アドレスにstTONを送金して下さい チェーンはBSCチェーンとなります。
          <br /><br />

          【アドレス】<br />
          0x51ecB105DEA18FadB3BE6beC8e9f2AeDEA8a30F2
          <br /><br />

          stTONの送られてきた枚数を元に、送金元のアドレスにTON
          coin(BEP-20)が送られてきます。<br />
          ステーキングプールからの出金となる為、セキュリティの関係上、申請してからTONが届くまで1週間ほど時間がかかります。<br />
          <br />
          受け取り後、TONcoinを他に送金する際は、BEP-20のTON
          coinの取扱があるところのみとなりますのでご注意下さい。<br />
          チェーンを間違うと無くなる可能性があります。<br />
          宜しくお願い致します。
        </v-card-text>
        <v-card-actions class="justify-end">
          <div class="btn-cancel cursor" @click="close">close</div>
          <!--          <div class="btn-confirm cursor" @click="save">confirm</div>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "withdrawInputDialog",
  data() {
    return {
      dialog: false,
      inputAmount: "",
      tonAddress: "",
    };
  },
  methods: {
    isShow() {
      this.inputAmount = "";
      this.tonAddress = "";
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
      this.$emit("save", this.inputAmount, this.tonAddress);
      this.dialog = false;
    },
    changeAmountInput() {
      let _temp = String(this.inputAmount);
      _temp = _temp.replace(".", "");
      this.inputAmount = Number(_temp);
    },
    changeAmount() {
      // 只能输入大于1的整数
      const boolean = new RegExp("^[1-9]\\d*$").test(this.inputAmount);
      if (!boolean) {
        this.inputAmount = 10;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.connect-box {
  height: 150px;
  display: block;
  align-items: center;
}

.input-field {
  width: 100%;
  margin-bottom: 10px;
}

.dialog-card-box {
  color: #ffffff;
  background-color: #423a6c;
  border-radius: 10px;
}

.btn-confirm {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  border-radius: 20px;
  background-color: #1fc7d4;
  font-weight: 500;
  padding: 0 20px;
}

.btn-cancel {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  border-radius: 20px;
  background-color: #1fc7d4;
  font-weight: 500;
  padding: 0 20px;
}

.btn-cancel:hover {
  color: #cccccc;
}

.input-amount {
  color: #ffffff !important;
  caret-color: #ffffff !important;
}
</style>
