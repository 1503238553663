<template>
  <div class="progress-info-box" v-if="false">
    <div class="bright-1"></div>
    <div class="title-box">
      <div class="title-first">Fundraised:</div>
      <div class="title-second">{{ stakeMilestone }}% completed</div>
    </div>
    <div class="bar-bg-box">
      <div
        class="bar-bg-active"
        :style="{
          width: barBgWidth,
        }"
      ></div>
    </div>
    <!--    <ul class="bar-number-box">-->
    <!--      <li class="bar-number-first po-site" style="left: 0">0</li>-->
    <!--      <li class="bar-number po-site" style="left: 10%">100000</li>-->
    <!--      <li class="bar-number po-site" style="left: 30%">300000</li>-->
    <!--      <li class="bar-number po-site" style="left: 60%">600000</li>-->
    <!--      <li class="bar-number-last po-site">1000000</li>-->
    <!--    </ul>-->
  </div>
</template>

<script>
export default {
  name: "ProgressInfo",
  props: {
    stakeMilestone: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    barBgWidth() {
      return this.stakeMilestone + "%";
    },
  },
};
</script>

<style scoped lang="scss">
.progress-info-box {
  position: relative;
}

.bright-1 {
  width: 72.05px;
  height: 59.6px;
  background: #7eb2ff;
  filter: blur(45.5px);
  border-radius: 200px;
  position: absolute;
  right: 16%;
  top: -20px;
}

.bar-bg-box {
  width: 100%;
  height: 14px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #fab0bf;
  position: relative;
}

.bar-bg-active {
  height: 14px;
  background-color: #f9669a;
  border-radius: 20px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.title-box {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-first {
  font-size: 18px;
  color: #333333;
}

.title-second {
  font-size: 16px;
  color: #f9669a;
  padding-right: 20px;
}

.bar-number-box {
  width: 100%;
  height: 28px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  z-index: 2;
  li {
    line-height: 28px;
  }
}

.po-site {
  position: absolute;
}

.bar-number,
.bar-number-first,
.bar-number-last {
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  position: absolute;
}

.bar-number-last {
  left: 100%;
}

//.bar-number:after {
//  content: "";
//  width: 2px;
//  height: 14px;
//  background-color: #ffffff;
//  position: absolute;
//  left: 50%;
//  margin-left: -1px;
//  top: -14px;
//  border-radius: 4px;
//}

@media only screen and (max-width: 768px) {
  .bar-number-last {
    left: 94%;
  }
}
</style>
