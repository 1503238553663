import { Connect2MetamaskProcess } from "@/utils/connet";
import { browser } from "@/utils/utils";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    connectMetamaskBtnFun() {
      console.log("browser", browser.versions);
      Connect2MetamaskProcess()
        .then((res) => {
          if (res && res.length > 0) {
            this.$store.commit("user/walletAddress", res);
            // window.sessionStorage.setItem("walletAddress", res);
          } else {
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: this.$t("login.reconnect"),
            });
          }
        })
        .catch((err) => {
          console.log("err connect_meta_mask:", err);
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: this.$t("login.reconnect"),
          });
        });
    },
  },
};
