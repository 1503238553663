<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="500px">
      <v-card class="dialog-card-box">
        <v-card-title>Enter the amount of USDT you want to stake</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="connect-box">
          <v-text-field
            hide-details="auto"
            v-model.number="inputAmount"
            class="input-item"
            filled
            rounded
            dense
            color="#333333"
            type="number"
            hide-spin-buttons
            @change="changeAmount"
            @blur="changeAmount"
            @keydown.enter="changeAmount"
            @input="changeAmountInput"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <div class="btn-cancel cursor" @click="close">cancel</div>
          <div class="btn-confirm cursor" @click="save">confirm</div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "inputDialog",
  data() {
    return {
      dialog: false,
      inputAmount: "",
    };
  },
  methods: {
    isShow() {
      this.inputAmount = "";
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
      this.$emit("save", this.inputAmount);
      this.dialog = false;
    },
    changeAmountInput() {
      let _temp = String(this.inputAmount);
      _temp = _temp.replace(".", "");
      this.inputAmount = Number(_temp);
    },
    changeAmount() {
      // 只能输入大于1的整数
      const boolean = new RegExp("^[1-9]\\d*$").test(this.inputAmount);
      if (!boolean) {
        this.inputAmount = 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.connect-box {
  height: 120px;
  display: flex;
  align-items: center;
}

.dialog-card-box {
  color: #333333;
  background-color: #ffffff;
  border-radius: 10px;
}

.btn-confirm {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  border-radius: 20px;
  background-color: #f9669a;
  color: #ffffff;
  font-weight: 500;
  padding: 0 20px;
}

.btn-cancel {
  color: rgba(0, 0, 0, 0.6);
  padding: 0 10px;
  margin-right: 10px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;
}

.btn-cancel:hover {
  color: #cccccc;
}

.input-item {
  color: #333333 !important;
  caret-color: #333333 !important;
}
</style>
